import React, { useState, useEffect, useRef } from 'react'
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import Swal from 'sweetalert2'
import axios from 'axios'

const ControlCamera = ({ redux }) => {
    const [code, setCode] = useState();
    const [qrActivity, setQrActivity] = useState(false);

    let inputCode = useRef()

    useEffect(() => {
        const controlTicket = async () => {
            await axios.get(`${process.env.REACT_APP_API_URL}/api/tickets/control`, {
                params: {
                    code: code
                },
                headers: {
                    Authorization: 'Bearer ' + redux.token
                }
            })
                .then(function (response) {
                    Swal.fire({
                        icon: 'success',
                        text: response['data']['messages'],
                        showConfirmButton: false,
                        timer: 3000
                    })
                })
                .catch(function (error) {
                    console.log(error);

                    let err_msg = "";
                    if (error['response'] && error['response']['data'] && error['response']['data']['messages']) {
                        if (Array.isArray(error['response']['data']['messages'])) {
                            error['response']['data']['messages'].map(message => {
                                err_msg = err_msg + message + '<br>';
                            }
                            )
                        } else {
                            err_msg = error['response']['data']['messages'];
                        }
                    } else {
                        err_msg = "Bir hata oluştu";
                    }

                    Swal.fire({
                        icon: 'error',
                        html: err_msg,
                        showConfirmButton: false,
                        timer: 3000
                    })
                });
        }

        if (code) {
            controlTicket();
            setCode(null);
        }
    }, [code, redux])

    const preControlTicketByInput = () => {
        if(inputCode.current.value) {
            setCode(inputCode.current.value); 
            inputCode.current.value = null;
        } else {
            Swal.fire({
                icon: 'error',
                html: 'Bilet kontrolü için QR kod okutmalısınız',
                showConfirmButton: false,
                timer: 3000
            })    
        }
    }

    return (
        <div className="card">
            <div className="card-body">
                <button className="btn btn-light" onClick={() => setQrActivity(!qrActivity)}><i className="fas fa-camera text-dark"></i> Kamerayı {qrActivity ? "Kapat" : "Aç"}</button>

                {qrActivity &&
                    <div className="mt-2">
                        <BarcodeScannerComponent
                            onUpdate={(err, result) => {
                                if (result) { setCode(result.text); }
                            }}
                            delay={3000}
                        />
                        <p>{code}</p>
                    </div>
                }

                {!qrActivity &&
                    <div className="mt-2">
                        <div className="input-group">
                            <div className="input-group-text bg-dark border border-dark"><i className="fas fa-barcode fa-lg text-light"></i></div>
                            <input type="text" className="form-control border border-dark" placeholder="QR Kod Okutunuz" ref={inputCode} />
                            <button className="btn btn-outline-dark" type="button" onClick={preControlTicketByInput}>Bilet Kontrol</button>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default ControlCamera
