import React from 'react'

const Footer = () => {
    return (
        <div>
            <footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                © {new Date().getFullYear()} <a href="http://ikarusteknoloji.com">İkarus Teknoloji</a>
                            </div>
                            <div className="col-sm-6">
                                <div className="text-sm-end d-none d-sm-block">
                                    -
                                </div>
                            </div>
                        </div>
                    </div>
            </footer>
        </div>
    )
}

export default Footer
