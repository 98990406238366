import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import Header from '../structure/Header';
import Sidebar from '../structure/Sidebar';
import Footer from '../structure/Footer';

import { useSelector } from 'react-redux';
import CheckLicense from './CheckLicense';
import CheckLogin from './CheckLogin';

const PrivateRoute = ({ component: Component, roles: roles, title: title, ...rest }) => {
    CheckLicense();
    CheckLogin();
    const redux = useSelector((state) => state.redux);

    const getIndex = () => {
      let val = -1;

      if(roles) {
        roles.map((role) => {
          if(role == redux.user.role)
            val = role;
        })
      } else {
        val = 0;
      }

      return val;
    }
  
    return (
      <Route
        {...rest}
        render={props =>
          redux.token ? (
            getIndex() !== -1 ? (
            <div>
                <Header title={title} redux={redux}/>
                <Sidebar role={redux.user.role}/>
                <Component {...props} redux={redux} />
                <Footer/> 
            </div>
            ) : (
              <Redirect to={{ pathname: '/', state: { from: props.location, message: {content: "Bu sayfayı görüntülemeye izniniz bulunmamaktadır, anasayfaya yönlendirildiniz", color: "alert-danger", load: false} } }} />
            )
          ) : (
            <Redirect to={{ pathname: '/login', state: { from: props.location, message: {content: "Hesabınız doğrulanamadı, lütfen giriş yapınız", color: "alert-danger"} } }} />
          )
        }
      />
    )
}
  
export default PrivateRoute
