import { ActionTypes } from "../constants/action-types"

export const loginUser = (token, user) => {
    return {
        type: ActionTypes.LOGIN_USER,
        token: token,
        user: user
    }
};

export const logoutUser = () => {
    return {
        type: ActionTypes.LOGOUT_USER,
    }
};