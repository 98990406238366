import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import Login from './components/Login';
import Index from './components/Index';
import PageNotFound from "./components/PageNotFound";

import Enter from "./components/tickets/Enter";
import Transfer from "./components/tickets/Transfer";
import PrivateRoute from "./components/inspection/PrivateRoute";
import Sell from "./components/tickets/Sell";
import Control from "./components/tickets/Control";
import Report from "./components/Report";

/*
  Roller
  1: Muhasebe
  2: Kasa
  3: Saha Görevlisi
  4: Yönetici
  5: Admin
*/

function App() {

  return (
    <Router>
        <Switch>
          <Route path="/login" component={() => <Login title="Giriş"/>}/>
          <PrivateRoute path="/" exact component={Index} title="Anasayfa"/>   
          <PrivateRoute path="/tickets/enter" component={Enter} title="Bilet Giriş" roles={[1, 5]}/> 
          <PrivateRoute path="/tickets/transfer" component={Transfer} title="Bilet Transfer" roles={[1, 5]}/>   
          <PrivateRoute path="/tickets/sell" component={Sell} title="Bilet Satış" roles={[2, 5]}/>
          <PrivateRoute path="/tickets/control" component={Control} title="Bilet Kontrol" roles={[2, 3, 4, 5]}/>
          <PrivateRoute path="/report" component={Report} title="Rapor" roles={[1, 4, 5]}/>
          <Route path="*" component={PageNotFound}/>
        </Switch>
    </Router>
  );
}

export default App;
