import React, { useState, useEffect } from 'react'
import Chart from "react-apexcharts"
import axios from 'axios'

import "datatables.net-dt/js/dataTables.dataTables"
import $ from 'jquery'

import loadjs from 'loadjs';
import Moment from 'react-moment';

import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { FormControl } from 'react-bootstrap'
import moment from "moment"

const Report = ({ redux }) => {
    const [lineChart, setLineChart] = useState({
        options: {
            series: [],
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: [5, 7, 5],
                curve: 'straight',
                dashArray: [0, 8, 5]
            },
            legend: {
                tooltipHoverFormatter: function (val, opts) {
                    return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
                }
            },
            markers: {
                size: 0,
                hover: {
                    sizeOffset: 6
                }
            },
            xaxis: {
                categories: [],
            },
            grid: {
                borderColor: '#f1f1f1',
            }
        }
    });

    const [barChart, setBarChart] = useState({
        options: {
            series: [{
                name: "Bugün",
                data: []
            }, {
                name: "Genel",
                data: []
            }],
            chart: {
                type: 'bar',
                height: 430
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: false,
                offsetX: -6,
                style: {
                    fontSize: '12px',
                    colors: ['#fff']
                }
            },
            stroke: {
                show: true,
                width: 1,
                colors: ['#fff']
            },
            tooltip: {
                shared: true,
                intersect: false
            },
            xaxis: {
                categories: [],
            },
        }
    });

    const [pieChart, setPieChart] = useState({
        options: {
            series: [],
            chart: {
                width: 380,
                type: 'pie',
            },
            labels: ['Ücretsiz & Kullanılan', 'Ücretsiz & Kullanılmayan', 'Ücretsiz & Kullanılmayan & Süresi Geçen', 'Ücretli & Kullanılan', 'Ücretli & Kullanılmayan', 'Ücretli & Kullanılmayan & Süresi Geçen'],
            responsive: [{
                breakpoint: 600,
                options: {
                    chart: {
                        height: 'auto'
                    },
                    legend: {
                        show: false,
                    }
                }
            }, {
                breakpoint: 1200,
                options: {
                    legend: {
                        show: true,
                        position: 'right',
                        width: '100%'
                    }
                }
            }, {
                breakpoint: 1400,
                options: {
                    chart: {
                        width: '100%',
                        height: 298
                    },
                    legend: {
                        show: true,
                        position: 'bottom',
                        width: 450
                    }
                }
            }],
            dataLabels: {
                enabled: false
            },
            legend: {
                show: true,
                position: 'right',
                width: 238
            }
        }
    });

    const [reportValues, setReportValues] = useState();

    useEffect(() => {
        const getReportValues = async () => {
            await axios.get(`${process.env.REACT_APP_API_URL}/api/report`, {
                headers: {
                    Authorization: 'Bearer ' + redux.token
                }
            })
                .then(function (response) {
                    setReportValues({ transfer_table: response.data.tables.transfer_table, sales_table: response.data.tables.sales_table, cards: response.data.cards, bordered_cards: response.data.bordered_cards })
                    setLineChart(prevState => ({
                        ...prevState,
                        options: {
                            ...prevState.options,
                            chart: {
                                ...prevState.options.chart,
                                //type: 'line'
                            },
                            series: response.data.line_chart.series,
                            xaxis: {
                                ...prevState.options.xaxis,
                                categories: response.data.line_chart.categories
                            }
                        }
                    }))
                    setBarChart(prevState => ({
                        ...prevState,
                        options: {
                            ...prevState.options,
                            series: [
                                {
                                    data: response.data.bar_chart.series[0]
                                },
                                {
                                    data: response.data.bar_chart.series[1]
                                }
                            ],
                            xaxis: {
                                ...prevState.options.xaxis,
                                categories: response.data.bar_chart.categories
                            }
                        }
                    }))
                    setPieChart(prevState => ({
                        ...prevState,
                        options: {
                            ...prevState.options,
                            series: response.data.pie_chart.series
                        }
                    }))
                })
                .catch(function (error) {
                    console.log(error)
                })
        }

        if (!reportValues)
            getReportValues();

        if (reportValues) {
            $('#transfer-table').DataTable().clear().destroy();
            $('#transfer-body').empty();
            if(reportValues.transfer_table && reportValues.transfer_table.length > 0)
                $('#transfer-body').append(printTransferTable());
            //$('#transfer-table').DataTable({ order: [] }).draw();

            $('#sales-table').DataTable().clear().destroy();
            $('#sales-body').empty();
            if(reportValues.sales_table && reportValues.sales_table.length > 0)
                $('#sales-body').append(printSalesTable());
            //$('#sales-table').DataTable({ order: [] }).draw();

            loadjs("/assets/js/pages/datatables.init.js", () => { });
        }

    }, [reportValues])

    const printTransferTable = () => {
        let transferTable =
        `<h4 class="card-title mb-4">Transfer Tablosu</h4>
        <table id="transfer-table" class="table table-bordered dt-responsive nowrap w-100">
            <thead style="text-align: center">
                <tr>
                    <th>#</th>
                    <th>Miktar</th>
                    <th>Tarih</th>
                </tr>
            </thead>
            <tbody style="text-align: center">`;

        reportValues && reportValues.transfer_table &&
            reportValues.transfer_table.map((tfItem) =>
                transferTable = transferTable +
                `<tr>
                    <td>${tfItem.id}</td>
                    <td>${tfItem.amount}</td>
                    <td>
                        ${moment(tfItem.created_at).format("DD/MM/YY HH:mm")}
                    </td>
                </tr>`
            )

        transferTable = transferTable + `</tbody></table>`
        return transferTable
    }

    const printSalesTable = () => {
        let salesTable =
        `<h4 class="card-title mb-4">Satış Tablosu</h4>
        <table id="sales-table" class="table table-bordered dt-responsive nowrap w-100">
            <thead style="text-align: center">
                <tr>
                    <th>#</th>
                    <th>Kullanıcı</th>
                    <th>Tarih</th>
                    <th>Durum</th>
                    <th>Ödeme</th>
                </tr>
            </thead>
            <tbody style="text-align: center">`;

        reportValues && reportValues.sales_table &&
            reportValues.sales_table.map((slItem) =>
                salesTable = salesTable +
                `<tr>
                    <td>${slItem.id}</td>
                    <td>${slItem.user.name}</td>
                    <td>
                        ${moment(slItem.created_at).format("DD/MM/YY HH:mm")}
                    </td>
                    <td>
                        ${slItem.status ?
                    `<span class="badge badge-pill badge-soft-danger font-size-11">Kullanılmış</span>` :
                    `<span class="badge badge-pill badge-soft-success font-size-11">Kullanılabilir</span>`
                }
                    </td>
                    <td>
                        ${slItem.complimentary === 0 ?
                    `<span class="badge badge-pill badge-soft-primary font-size-11">Ücretli</span>` :
                    `<span class="badge badge-pill badge-soft-warning font-size-11">Ücretsiz</span>`
                }
                    </td>
                </tr>`
            )

        salesTable = salesTable + `</tbody></table>`
        return salesTable
    }

    /* DateTimePicker değişkenleri ve fonksiyonları */

    if ($('.rangebuttontextstyle:contains("Custom Range")').length > 0)
        $('.rangebuttontextstyle:contains("Custom Range")').html("Özel Aralık");

    let now = new Date();
    let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    const [dtState, setDtState] = useState({
        start: start,
        end: end
    })

    const applyCallback = (startDate, endDate) => {
        let value = `${startDate.format(
            "DD.MM.YYYY HH:mm:ss"
        )} - ${endDate.format("DD.MM.YYYY HH:mm:ss")}`;
        getFilteredReportValues(value);

        setDtState({
            start: startDate,
            end: endDate,
            value: value
        })
    }

    const getFilteredReportValues = async (range) => {
        await axios.get(`${process.env.REACT_APP_API_URL}/api/reportfilter`, {
            params: {
                range: range
            },
            headers: {
                Authorization: 'Bearer ' + redux.token
            }
        })
            .then(function (response) {
                setLineChart(prevState => ({
                    ...prevState,
                    options: {
                        ...prevState.options,
                        chart: {
                            ...prevState.options.chart,
                            //type: 'line'
                        },
                        series: response.data.line_chart.series,
                        xaxis: {
                            ...prevState.options.xaxis,
                            categories: response.data.line_chart.categories
                        }
                    }
                }))

                setReportValues({ transfer_table: response.data.tables.transfer_table, sales_table: response.data.tables.sales_table, cards: response.data.cards, bordered_cards: response.data.bordered_cards })
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    now = new Date();
    start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    end = moment(start).add(1, "days").subtract(1, "seconds");
    let ranges = {
        "Yalnızca Bugün": [moment(start), moment(end)],
        "Yalnızca Dün": [moment(start).subtract(1, "days"), moment(end).subtract(1, "days")],
        "3 Gün": [moment(start).subtract(3, "days"), moment(end)],
        "1 Hafta": [moment(start).subtract(7, "days"), moment(end)],
        "1 Ay": [moment(start).subtract(1, "months"), moment(end)],
        "1 Yıl": [moment(start).subtract(1, "years"), moment(end)]
    }
    let local = {
        "format": "DD-MM-YYYY HH:mm",
        "sundayFirst": false,
        days: ['Pzt', 'Sal', 'Çrş', 'Prş', 'Cum', 'Cmt', 'Pzr'],
        months: [
            'Ocak',
            'Şubat',
            'Mart',
            'Nisan',
            'Mayıs',
            'Haziran',
            'Temmuz',
            'Ağustos',
            'Eylül',
            'Ekim',
            'Kasım',
            'Aralık',],
        fromDate: 'Bu tarihten',
        toDate: 'Bu tarihe',
        selectingFrom: 'Bu seçimden',
        selectingTo: 'Bu seçime',
        maxDate: 'Maksimum Tarih',
        close: 'Kapat',
        apply: 'Filtrele',
        cancel: 'İptal'
    }
    let maxDate = moment(start).add(24, "hour")

    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Rapor</h4>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">Menü</li>
                                        <li className="breadcrumb-item active">Rapor</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="card mini-stats-wid bg-info">
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <p className="text-white fw-medium">Giriş Yapılan Bilet</p>
                                                    <h4 className="text-white mb-0">{(reportValues && reportValues.bordered_cards) ? reportValues.bordered_cards.sum_entered_tickets_bordered_card : "-"}</h4>
                                                </div>

                                                <div className="flex-shrink-0 align-self-center">
                                                    <div className="mini-stat-icon avatar-sm rounded-circle bg-white">
                                                        <span className="avatar-title bg-white">
                                                            <i className="bx bx-send font-size-24 text-dark"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card mini-stats-wid bg-info">
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <p className="text-white fw-medium">Kalan Bilet</p>
                                                    <h4 className="text-white mb-0">{(reportValues && reportValues.bordered_cards) ? reportValues.bordered_cards.sum_remaining_tickets_bordered_card : "-"}</h4>
                                                </div>

                                                <div className="flex-shrink-0 align-self-center ">
                                                    <div className="avatar-sm rounded-circle bg-white mini-stat-icon">
                                                        <span className="avatar-title rounded-circle bg-white">
                                                            <i className="bx bx-radar font-size-24 text-dark"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-6">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">Bugün/Genel İstatistikler</h4>

                                    <Chart
                                        options={barChart.options}
                                        series={barChart.options.series}
                                        type={barChart.options.chart.type}
                                        height="250"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">Bilet İstatistikleri</h4>

                                    <Chart
                                        options={pieChart.options}
                                        series={pieChart.options.series}
                                        type={pieChart.options.chart.type}
                                        height="263"
                                        width="547"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">Filtreleme {!dtState.value && <small>(Şu an son 1 hafta verileri gösterilmektedir)</small>}</h4>
                                    <DateTimeRangeContainer
                                        ranges={ranges}
                                        start={dtState.start}
                                        end={dtState.end}
                                        local={local}
                                        maxDate={maxDate}
                                        applyCallback={applyCallback}
                                    >
                                        <FormControl
                                            id="formControlsTextB"
                                            type="text"
                                            label="Text"
                                            placeholder="Filtreleme Aralığı Seçiniz"
                                            style={{ cursor: "pointer" }}
                                            value={dtState.value && dtState.value}
                                        />
                                    </DateTimeRangeContainer>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="card mini-stats-wid">
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <p className="text-muted fw-medium">Transfer</p>
                                                    <h4 className="mb-0">{(reportValues && reportValues.cards) ? reportValues.cards.sum_transferred_tickets_card : "-"}</h4>
                                                </div>

                                                <div className="flex-shrink-0 align-self-center">
                                                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                                        <span className="avatar-title">
                                                            <i className="bx bx-copy-alt font-size-24"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card mini-stats-wid">
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <p className="text-muted fw-medium">Satılan</p>
                                                    <h4 className="mb-0">{(reportValues && reportValues.cards) ? reportValues.cards.sum_sold_tickets_card : "-"}</h4>
                                                </div>

                                                <div className="flex-shrink-0 align-self-center ">
                                                    <div className="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                                        <span className="avatar-title rounded-circle bg-primary">
                                                            <i className="bx bx-purchase-tag-alt font-size-24"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card mini-stats-wid">
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <p className="text-muted fw-medium">Ücretsiz</p>
                                                    <h4 className="mb-0">{(reportValues && reportValues.cards) ? reportValues.cards.sum_complimentary_tickets_card : "-"}</h4>
                                                </div>

                                                <div className="flex-shrink-0 align-self-center">
                                                    <div className="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                                        <span className="avatar-title rounded-circle bg-primary">
                                                            <i className="bx bx-gift font-size-24"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card mini-stats-wid">
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <p className="text-muted fw-medium">Kullanılan</p>
                                                    <h4 className="mb-0">{(reportValues && reportValues.cards) ? reportValues.cards.sum_used_tickets_card : "-"}</h4>
                                                </div>

                                                <div className="flex-shrink-0 align-self-center">
                                                    <div className="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                                        <span className="avatar-title rounded-circle bg-primary">
                                                            <i className="bx bx-archive-in font-size-24"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">Bilet Grafiği</h4>

                                    <Chart
                                        options={lineChart.options}
                                        series={lineChart.options.series}
                                        type={lineChart.options.chart.type}
                                        height="350"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body" id="transfer-body">
                                    <h4 className="card-title mb-4">Transfer Tablosu</h4>
                                    <table id="transfer-table" className="table table-bordered dt-responsive nowrap w-100">
                                        <thead style={{ textAlign: 'center' }}>
                                            <tr>
                                                <th>#</th>
                                                <th>Miktar</th>
                                                <th>Tarih</th>
                                            </tr>
                                        </thead>

                                        <tbody style={{ textAlign: 'center' }}>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body" id="sales-body">
                                    <h4 className="card-title mb-4">Satış Tablosu</h4>
                                    <table id="sales-table" className="table table-bordered dt-responsive nowrap w-100">
                                        <thead style={{ textAlign: 'center' }}>
                                            <tr>
                                                <th>#</th>
                                                <th>Kullanıcı</th>
                                                <th>Tarih</th>
                                                <th>Durum</th>
                                                <th>Ödeme</th>
                                            </tr>
                                        </thead>


                                        <tbody style={{ textAlign: 'center' }}>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Report
