import React from 'react'
import { Link } from "react-router-dom";
import $ from 'jquery';

const Sidebar = ({role}) => {
    const removeActiveClass = (e) => {
        $("#sidebar-menu .waves-effect a").removeClass("active"); //Birden fazla beyaz renkli liste elemanı olmaması için
        if($("body").hasClass("sidebar-enable")) $("body").removeClass("sidebar-enable"); // Sidebar' dan seçim yapıldığında küçük ekranlarda Sidebar'ın gizlenmesi için

        if(!$(e.currentTarget).hasClass("mm-active")) // Sidebar' da beyaz renkli elemana tekrar basıldığında beyazlığının gitmemesi için
            $(e.currentTarget).addClass("mm-active");
    }

    return (
        <div>
            <div className="vertical-menu">
                <div data-simplebar className="h-100">

                    <div id="sidebar-menu">
                        <ul className="metismenu list-unstyled" id="side-menu">
                            <li className="menu-title" key="t-menu">MENÜ</li>

                            <li className="waves-effect" onClick={removeActiveClass}>
                                <Link to="/">
                                    <i className="bx bx-home-circle"></i>
                                    <span key="t-dashboards">Anasayfa</span>
                                </Link>
                            </li>

                            {(role === 1 || role === 4 || role === 5) &&
                                <li className="waves-effect" onClick={removeActiveClass}>
                                    <Link to="/report">
                                        <i className="bx bxs-bar-chart-alt-2"></i>
                                        <span key="t-dashboards">Rapor</span>
                                    </Link>
                                </li>
                            }

                            <li className="menu-title" key="t-apps">BİLET</li>

                            {(role === 1 || role === 5) &&
                                <li className="waves-effect" onClick={removeActiveClass}>
                                    <Link to="/tickets/enter">
                                        <i className="bx bx-send"></i>
                                        <span key="t-calendar">Giriş</span>
                                    </Link>
                                </li>
                            }

                            {(role === 1 || role === 5) &&
                                <li className="waves-effect" onClick={removeActiveClass}>
                                    <Link to="/tickets/transfer">
                                        <i className="bx bx-transfer"></i>
                                        <span key="t-calendar">Transfer</span>
                                    </Link>
                                </li>
                            }

                            {(role === 2 || role === 5) &&
                                <li className="waves-effect" onClick={removeActiveClass}>
                                    <Link to="/tickets/sell">
                                        <i className="bx bx-dollar-circle"></i>
                                        <span key="t-calendar">Satış</span>
                                    </Link>
                                </li>
                            }

                            {(role === 2 || role === 3 || role === 4 || role === 5) &&
                                <li className="waves-effect" onClick={removeActiveClass}>
                                    <Link to="/tickets/control">
                                        <i className="bx bx-analyse"></i>
                                        <span key="t-calendar">Kontrol</span>
                                    </Link>
                                </li>
                            }

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar
