import React, {useState} from 'react'
import Swal from 'sweetalert2'
import axios from 'axios'

const Transfer = ({redux}) => {
    const [ticketAmount, setTicketAmount] = useState(0);
    const ticketTransferFunc = async (e) => {
        e.preventDefault();
        if(ticketAmount <= 0) {
            Swal.fire({
                icon: 'error',
                //title: 'Hata',
                text: "Transfer edilecek bilet sayısı 0'dan yüksek olmalıdır",
            })
        } else {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/tickets/transfer`, {
                amount: ticketAmount
            }, {headers: {
                Authorization: 'Bearer ' + redux.token
            }})
            .then(function (response) {
                Swal.fire({
                    icon: 'success',
                    text: response['data']['messages'],
                })
                setTicketAmount(0);
            })
            .catch(function (error) {
                console.log(error);

                let err_msg = "";
                if(error['response'] && error['response']['data'] && error['response']['data']['messages']) {
                    if(Array.isArray(error['response']['data']['messages'])) {
                        error['response']['data']['messages'].map(message => {
                            err_msg = err_msg + message + '<br>'; }
                        )
                    } else {
                        err_msg = error['response']['data']['messages'];
                    }
                } else {
                    err_msg = "Bir hata oluştu";
                }

                Swal.fire({
                    icon: 'error',
                    html: err_msg,
                })
            });
            
        }
    }

    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">BİLET TRANSFER</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">Bilet</li>
                                            <li className="breadcrumb-item active">Transfer</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-6">
                                <div className="card">
                                    <div className="card-body">

                                        <form>
                                            <div className="mb-3">
                                                <label htmlFor="formrow-firstname-input" className="form-label">Bilet Miktarı</label>
                                                <input type="number" className="form-control" id="formrow-firstname-input" value={ticketAmount} onChange={(e) => setTicketAmount(e.target.value)}/>
                                            </div>

                                            <div>
                                                <button className="btn btn-primary w-md" onClick={ticketTransferFunc}>Transfer Et</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                </div>
            </div>        
        </div>
    )
}

export default Transfer
