import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useHistory } from "react-router-dom"; 

import { useDispatch } from 'react-redux'
import { loginUser } from '../redux/actions/userActions'

import { useLocation } from "react-router-dom";

import loadjs from 'loadjs'; //history.push yapılan yerde loadjs yapıldı. push, app.jsy'i bozuyor.

const Login = (props) => {
    const [credentials, setCredentials] = useState({
        email: "",
        password: ""
    });
    const [messages, setMessages] = useState([]);

    let history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        document.title = props.title;
        if(location.state && location.state.message) {
            setMessages(messages => [...messages, {content: location.state.message.content, color: location.state.message.color}]);
            history.replace('/login', {});

            loadjs("/assets/js/app.js", () => {});
        }
    }, [location]);

    const loginFunc = async (e) => {
        e.preventDefault();
        setMessages([]);
        if(credentials.email === "" || credentials.password === "") {
            setMessages(messages => [...messages, {content: "Email ve şifre alanları boş olmamalıdır", color: "alert-danger"}]);
            return;
        }

        await axios.post(`${process.env.REACT_APP_API_URL}/api/login`, {
            email: credentials.email,
            password: credentials.password,
        })
        .then(function (response) {
            setCredentials({email: "", password: ""});
            response.data.user.role = parseInt(response.data.user.role);
            dispatch(loginUser(response.data.token, response.data.user))
            history.push('/');
            loadjs("/assets/js/app.js", () => {});

            //window.location.href="/";
        })
        .catch(function (error) {
            console.log(error);
            
            if(error['response'] && error['response']['data'] && error['response']['data']['messages']) {
                if(Array.isArray(error['response']['data']['messages'])) {
                    error['response']['data']['messages'].map(message => {
                        setMessages(messages => [...messages, {content: message, color: "alert-danger"}]); }
                    )
                } else {
                    setMessages(messages => [...messages, {content: error['response']['data']['messages'], color: "alert-danger"}]);
                }
            } else {
                setMessages(messages => [...messages, {content: "Bir hata oluştu", color: "alert-danger"}]);
            }
            setCredentials({email: "", password: ""});
        });
    }

    return (
        <div className="account-pages my-5 pt-sm-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">

                        {messages && messages.length > 0 &&
                            messages.map((message, index) =>
                                <div className={`alert ${message.color} alert-dismissible fade show`} role="alert" key={index}>
                                    {message.content}
                                    <button type="button" className="btn-close" aria-label="Close" onClick={() => setMessages(messages.filter((msg, i) => i !== index))}></button>
                                </div>
                            )
                        }

                        <div className="card overflow-hidden">
                            <div className="bg-primary bg-soft">
                                <div className="row">
                                    <div className="col-7">
                                        <div className="text-primary p-4">
                                            <h5 className="text-primary">Hoşgeldiniz!</h5>
                                            <p>Devam etmek için giriş yapınız.</p>
                                        </div>
                                    </div>
                                    <div className="col-5 align-self-end">
                                        <img src="assets/images/profile-img.png" alt="" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-0"> 
                                <div className="auth-logo">
                                    <a className="auth-logo-light">
                                        <div className="avatar-md profile-user-wid mb-4">
                                            <span className="avatar-title rounded-circle bg-light">
                                                <img src="assets/images/logo-light.svg" alt="" className="rounded-circle" height="34"/>
                                            </span>
                                        </div>
                                    </a>

                                    <a className="auth-logo-dark">
                                        <div className="avatar-md profile-user-wid mb-4">
                                            <span className="avatar-title rounded-circle bg-light">
                                                <img src="assets/images/robinson.svg" alt="" className="rounded-circle" height="85"/>
                                            </span>
                                        </div>
                                    </a>
                                </div>
                                <div className="p-2">
                                    <form className="form-horizontal" action="index.html">
        
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input type="text" className="form-control" id="email" placeholder="Email giriniz" value={credentials.email} onChange={(e)=>setCredentials({...credentials, email: e.target.value})}/>
                                        </div>
                
                                        <div className="mb-3">
                                            <label className="form-label">Şifre</label>
                                            <div className="input-group auth-pass-inputgroup">
                                                <input type="password" className="form-control" placeholder="Şifre giriniz" aria-label="Password" aria-describedby="password-addon" value={credentials.password} onChange={(e)=>setCredentials({...credentials, password: e.target.value})}/>
                                                <button className="btn btn-light " type="button" id="password-addon"><i className="mdi mdi-eye-outline"></i></button>
                                            </div>
                                        </div>

                                        {/*
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="remember-check"/>
                                            <label className="form-check-label" htmlFor="remember-check">
                                                Remember me
                                            </label>
                                        </div>
                                        */}
                                        
                                        <div className="mt-3 mb-2 d-grid">
                                            <button className="btn btn-primary waves-effect waves-light" onClick={loginFunc}>Giriş</button>
                                        </div>
            
                                        {/*
                                        <div className="mt-4 text-center">
                                            <h5 className="font-size-14 mb-3">Sign in with</h5>
            
                                            <ul className="list-inline">
                                                <li className="list-inline-item">
                                                    <a href="" className="social-list-item bg-primary text-white border-primary">
                                                        <i className="mdi mdi-facebook"></i>
                                                    </a>
                                                </li>
                                                <li className="list-inline-item">
                                                    <a href="" className="social-list-item bg-info text-white border-info">
                                                        <i className="mdi mdi-twitter"></i>
                                                    </a>
                                                </li>
                                                <li className="list-inline-item">
                                                    <a href="" className="social-list-item bg-danger text-white border-danger">
                                                        <i className="mdi mdi-google"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        

                                        <div className="mt-4 text-center">
                                            <a href="auth-recoverpw.html" className="text-muted"><i className="mdi mdi-lock me-1"></i> Forgot your password?</a>
                                        </div>
                                        */}

                                    </form>
                                </div>
            
                            </div>
                        </div>
                        <div className="mt-5 text-center">
                            
                            <div>
                                {/*<p>Don't have an account ? <a href="auth-register.html" className="fw-medium text-primary"> Signup now </a> </p>*/}
                                <p>© {new Date().getFullYear()} <a href="http://ikarusteknoloji.com">İkarus Teknoloji</a></p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
