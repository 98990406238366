import React from 'react'
import axios from 'axios'

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux'
import { logoutUser } from '../../redux/actions/userActions'

const CheckLogin = () => {
    const redux = useSelector((state) => state.redux);
    const dispatch = useDispatch();

    const getUserDetailsFunc = async () => {
        if(redux.token) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user`, {headers: {
                    Authorization: 'Bearer ' + redux.token
                }});
                if(response.data.name != redux.user.name || response.data.role != redux.user.role)
                    dispatch(logoutUser());
            } catch (error) {
                console.error(error);
                dispatch(logoutUser());
            }
        }
    }
    
    getUserDetailsFunc();
}

export default CheckLogin
