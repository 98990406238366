import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from "react-router-dom";
import loadjs from 'loadjs';
import axios from 'axios';
import Moment from 'react-moment';
import ControlCamera from './tickets/helpers/ControlCamera';

import moment from "moment"
import $ from 'jquery'
import "datatables.net-dt/js/dataTables.dataTables"

const Index = ({ redux }) => {
    const location = useLocation();
    const [message, setMessage] = useState();
    const [dashboardValues, setDashboardValues] = useState();
    let history = useHistory();

    useEffect(() => {
        if (location.state && location.state.message) {
            setMessage(location.state.message);
            history.replace('/', {});

            if (location.state.message.load)
                loadjs("/assets/js/app.js", () => { });
        }
    }, [location]);

    useEffect(() => {
        if (dashboardValues && dashboardValues.last_payments && dashboardValues.last_payments.length > 0) {
            $('#sales-table').DataTable().clear().destroy();
            $('#sales-body').empty();
            $('#sales-body').append(printSalesTable());

            loadjs("/assets/js/pages/datatables.init.js", () => { });
        }
    }, [dashboardValues])

    useEffect(() => {
        const getDashboardValues = async () => {
            await axios.get(`${process.env.REACT_APP_API_URL}/api/dashboard`, {
                headers: {
                    Authorization: 'Bearer ' + redux.token
                }
            })
                .then(function (response) {
                    setDashboardValues(response.data);
                })
                .catch(function (error) {
                    console.log(error)
                })
        }

        if (!dashboardValues)
            getDashboardValues();

        setInterval(() => {
            getDashboardValues();
        }, 10000);
    }, [])

    const printSalesTable = () => {
        let salesTable =
            `<table id="sales-table" class="table table-bordered dt-responsive nowrap w-100">
            <thead style="text-align: center">
                <tr>
                    <th>#</th>
                    <th>Kullanıcı</th>
                    <th>Tarih</th>
                    <th>Durum</th>
                    <th>Ödeme</th>
                </tr>
            </thead>
            <tbody style="text-align: center">`;

        dashboardValues && dashboardValues.last_payments &&
            dashboardValues.last_payments.map((slItem) =>
                salesTable = salesTable +
                `<tr>
                    <td>${slItem.id}</td>
                    <td>${slItem.user.name}</td>
                    <td>
                        ${moment(slItem.created_at).format("DD/MM/YY HH:mm")}
                    </td>
                    <td>
                        ${slItem.status ?
                    `<span class="badge badge-pill badge-soft-danger font-size-11">Kullanılmış</span>` :
                    `<span class="badge badge-pill badge-soft-success font-size-11">Kullanılabilir</span>`
                }
                    </td>
                    <td>
                        ${slItem.complimentary === 0 ?
                    `<span class="badge badge-pill badge-soft-primary font-size-11">Ücretli</span>` :
                    `<span class="badge badge-pill badge-soft-warning font-size-11">Ücretsiz</span>`
                }
                    </td>
                </tr>`
            )

        salesTable = salesTable + `</tbody></table>`
        return salesTable
    }

    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    {message && message.content &&
                        <div className={`alert ${message.color} alert-dismissible fade show`} role="alert">
                            {message.content}
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => setMessage({})}></button>
                        </div>
                    }

                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Anasayfa</h4>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">Menü</li>
                                        <li className="breadcrumb-item active">Anasayfa</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-4">
                            <div className="card overflow-hidden">
                                <div className="bg-primary bg-soft">
                                    <div className="row">
                                        <div className="col-7">

                                        </div>
                                        <div className="col-5 align-self-end">
                                            <img src="assets/images/profile-img.png" alt="" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body pt-0">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <img src="/assets/images/users/user.jpg" alt="" className="img-thumbnail rounded-circle" />
                                            </div>
                                            <h5 className="font-size-15 text-truncate">{redux.user.name}</h5>
                                        </div>

                                        <div className="col-sm-8">
                                            <div className="pt-4">

                                                <div className="row">
                                                    <div className="col-12">
                                                        {redux.user.role === 1 &&
                                                            <p className="text-muted mb-0" style={{ textAlign: 'justify' }}>
                                                                Kasaya bilet transferi operasyonunu yürütür. Satılan, transfer edilen ve ücretsiz verilen biletlerin raporlarını alır. Günlük ve dönemsel raporları görme yetkisine sahiptir.
                                                            </p>
                                                        }
                                                        {redux.user.role === 2 &&
                                                            <p className="text-muted mb-0" style={{ textAlign: 'justify' }}>
                                                                Bilet satma ve kontrol etme operasyonunu yürütür. Gerektiğinde ücretsiz bilet verebilir.  Satılmış olan biletlerin kullanım durumlarının sorgulanması, doğrulanması operasyonunu yönetir. Genel operasyon bilgilerini görme yetkisine sahiptir.
                                                            </p>
                                                        }
                                                        {redux.user.role === 3 &&
                                                            <p className="text-muted mb-0" style={{ textAlign: 'justify' }}>
                                                                Satılmış olan biletlerin kullanım durumlarının sorgulanması, doğrulanması operasyonunu yönetir.
                                                            </p>
                                                        }
                                                        {redux.user.role === 4 &&
                                                            <p className="text-muted mb-0" style={{ textAlign: 'justify' }}>
                                                                Biletleme ve kullanım operasyonunun bütününü izler. Yürütülen operasyonun günlük ve dönemsel raporlarını görme yetkisine sahiptir.
                                                            </p>
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8">

                            {redux.user.role === 1 &&
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="card mini-stats-wid">
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium">Transfer</p>
                                                        <h4 className="mb-0">{(dashboardValues && dashboardValues.cards) ? dashboardValues.cards.sum_transferred_tickets : "-"}</h4>
                                                    </div>

                                                    <div className="flex-shrink-0 align-self-center">
                                                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                                            <span className="avatar-title">
                                                                <i className="bx bx-copy-alt font-size-24"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card mini-stats-wid">
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium">Satılan</p>
                                                        <h4 className="mb-0">{(dashboardValues && dashboardValues.cards) ? dashboardValues.cards.sum_sold_tickets : "-"}</h4>
                                                    </div>

                                                    <div className="flex-shrink-0 align-self-center ">
                                                        <div className="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                                            <span className="avatar-title rounded-circle bg-primary">
                                                                <i className="bx bx-purchase-tag-alt font-size-24"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card mini-stats-wid">
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium">Ücretsiz</p>
                                                        <h4 className="mb-0">{(dashboardValues && dashboardValues.cards) ? dashboardValues.cards.sum_complimentary_tickets : "-"}</h4>
                                                    </div>

                                                    <div className="flex-shrink-0 align-self-center">
                                                        <div className="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                                            <span className="avatar-title rounded-circle bg-primary">
                                                                <i className="bx bx-gift font-size-24"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {redux.user.role === 2 &&
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="card mini-stats-wid">
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium">Satılan</p>
                                                        <h4 className="mb-0">{(dashboardValues && dashboardValues.cards) ? dashboardValues.cards.sum_sold_tickets : "-"}</h4>
                                                    </div>

                                                    <div className="flex-shrink-0 align-self-center ">
                                                        <div className="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                                            <span className="avatar-title rounded-circle bg-primary">
                                                                <i className="bx bx-purchase-tag-alt font-size-24"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card mini-stats-wid">
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium">Ücretsiz</p>
                                                        <h4 className="mb-0">{(dashboardValues && dashboardValues.cards) ? dashboardValues.cards.sum_complimentary_tickets : "-"}</h4>
                                                    </div>

                                                    <div className="flex-shrink-0 align-self-center">
                                                        <div className="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                                            <span className="avatar-title rounded-circle bg-primary">
                                                                <i className="bx bx-gift font-size-24"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card mini-stats-wid">
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium">Kalan</p>
                                                        <h4 className="mb-0">{(dashboardValues && dashboardValues.cards) ? dashboardValues.cards.sum_remaining_tickets : "-"}</h4>
                                                    </div>

                                                    <div className="flex-shrink-0 align-self-center">
                                                        <div className="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                                            <span className="avatar-title rounded-circle bg-primary">
                                                                <i className="bx bxs-book-content font-size-24"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {redux.user.role == 3 &&
                                <ControlCamera redux={redux} />
                            }

                            {(redux.user.role === 4 || redux.user.role === 5) &&
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="card mini-stats-wid">
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium">Transfer</p>
                                                        <h4 className="mb-0">{(dashboardValues && dashboardValues.cards) ? dashboardValues.cards.sum_transferred_tickets : "-"}</h4>
                                                    </div>

                                                    <div className="flex-shrink-0 align-self-center">
                                                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                                            <span className="avatar-title">
                                                                <i className="bx bx-copy-alt font-size-24"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="card mini-stats-wid">
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium">Satılan</p>
                                                        <h4 className="mb-0">{(dashboardValues && dashboardValues.cards) ? dashboardValues.cards.sum_sold_tickets : "-"}</h4>
                                                    </div>

                                                    <div className="flex-shrink-0 align-self-center ">
                                                        <div className="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                                            <span className="avatar-title rounded-circle bg-primary">
                                                                <i className="bx bx-purchase-tag-alt font-size-24"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="card mini-stats-wid">
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium">Ücretsiz</p>
                                                        <h4 className="mb-0">{(dashboardValues && dashboardValues.cards) ? dashboardValues.cards.sum_complimentary_tickets : "-"}</h4>
                                                    </div>

                                                    <div className="flex-shrink-0 align-self-center">
                                                        <div className="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                                            <span className="avatar-title rounded-circle bg-primary">
                                                                <i className="bx bx-gift font-size-24"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="card mini-stats-wid">
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium">Kullanılan</p>
                                                        <h4 className="mb-0">{(dashboardValues && dashboardValues.cards) ? dashboardValues.cards.sum_used_tickets : "-"}</h4>
                                                    </div>

                                                    <div className="flex-shrink-0 align-self-center">
                                                        <div className="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                                            <span className="avatar-title rounded-circle bg-primary">
                                                                <i className="bx bx-archive-in font-size-24"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {redux.user.role !== 3 &&
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Son Satışlar</h4>
                                        <div className="table-responsive" id="sales-body">
                                            <table id="sales-table" className="table align-middle table-nowrap mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th style={{ width: '20px' }}>
                                                            <div className="form-check font-size-16 align-middle">
                                                                <input className="form-check-input" type="checkbox" id="transactionCheck01" />
                                                                <label className="form-check-label" htmlFor="transactionCheck01"></label>
                                                            </div>
                                                        </th>
                                                        <th className="align-middle">#</th>
                                                        <th className="align-middle">Kullanıcı</th>
                                                        <th className="align-middle">Tarih</th>
                                                        <th className="align-middle">Durum</th>
                                                        <th className="align-middle">Ödeme</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Index
