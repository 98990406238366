import { ActionTypes } from "../constants/action-types";

const initialState = {}

export const userReducer = (state = initialState, { type, token, user }) => {
    switch (type) {
        case ActionTypes.LOGIN_USER:
            return {...state, token: token, user: user}
        case ActionTypes.LOGOUT_USER:
            return {}
        default:
            return state;
    }
}