import React from 'react'
import ControlCamera from './helpers/ControlCamera'

const Control = ({redux}) => {
    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">BİLET KONTROL</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">Bilet</li>
                                            <li className="breadcrumb-item active">Kontrol</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-6">
                                <ControlCamera redux={redux} />
                            </div>
                        </div>

                </div>
            </div>        
        </div>
    )
}

export default Control
