import React, {useEffect} from 'react'
import { Link } from "react-router-dom";

const PageNotFound = () => {
    useEffect(() => {
        document.title = "Sayfa Bulunamadı";
    }, []);

    return (
        <div className="account-pages my-5 pt-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-center mb-5">
                            <h1 className="display-2 fw-medium">4<i className="bx bx-buoy bx-spin text-primary display-3"></i>4</h1>
                            <h4 className="text-uppercase">Sayfa bulunamadı</h4>
                            <div className="mt-5 text-center">
                                <Link to={{pathname: "/", state:{ message: { content: "Anasayfaya yönlendirildiniz", color: "alert-warning", load: true} }}}>
                                    <button className="btn btn-primary waves-effect waves-light">
                                        Anasayfaya Geri Dön
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-8 col-xl-6">
                        <div>
                            <img src="assets/images/error-img.png" alt="" className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound
