import React, {useState, useEffect, useRef} from 'react'
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import Swal from 'sweetalert2'
import axios from 'axios'

const Sell = ({redux}) => {
    const [code, setCode] = useState();
    const [qrActivity, setQrActivity] = useState(false);
    const [complimentary, setComplimentary] = useState(false);

    let inputCode = useRef()

    useEffect(() => {
        const sellTicket = async () => {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/tickets/sell`, {
                code: code,
                complimentary: complimentary
            }, {headers: {
                Authorization: 'Bearer ' + redux.token
            }})
            .then(function (response) {
                Swal.fire({
                    icon: 'success',
                    text: response['data']['messages'],
                    showConfirmButton: false,
                    timer: 3000
                })  

                if(complimentary)
                    setComplimentary(false);
            })
            .catch(function (error) {
                console.log(error);

                let err_msg = "";
                if(error['response'] && error['response']['data'] && error['response']['data']['messages']) {
                    if(Array.isArray(error['response']['data']['messages'])) {
                        error['response']['data']['messages'].map(message => {
                            err_msg = err_msg + message + '<br>'; }
                        )
                    } else {
                        err_msg = error['response']['data']['messages'];
                    }
                } else {
                    err_msg = "Bir hata oluştu";
                }

                Swal.fire({
                    icon: 'error',
                    html: err_msg,
                    showConfirmButton: false,
                    timer: 3000
                })                
            });
        }

        if(code) {
            sellTicket();
            setCode(null);
        }
    }, [code, redux])

    const preSellTicketByInput = () => {
        if(inputCode.current.value) {
            setCode(inputCode.current.value); 
            inputCode.current.value = null;
        } else {
            Swal.fire({
                icon: 'error',
                html: 'Bilet satışı için QR kod okutmalısınız',
                showConfirmButton: false,
                timer: 3000
            })    
        }
    }

    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">BİLET SATIŞ</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">Bilet</li>
                                            <li className="breadcrumb-item active">Satış</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-6">
                                                <button className="btn btn-light" onClick={() => setQrActivity(!qrActivity)}><i className="fas fa-camera text-dark"></i> Kamerayı {qrActivity?"Kapat":"Aç"}</button>
                                            </div>
                                            <div className="col-6 d-flex justify-content-end" style={{flexWrap: 'wrap', alignItems: 'stretch', columnGap: '0.875rem'}}>
                                                <h4 className="card-title" style={{marginTop: 3}}>Ücretsiz Bilet</h4>
                                                <div className="square-switch">
                                                    <input type="checkbox" id="square-switch3" switch="bool" checked={complimentary} onChange={() => setComplimentary(!complimentary)} />
                                                    <label htmlFor="square-switch3" data-on-label="E"
                                                    data-off-label="H"></label>
                                                </div>
                                            </div>
                                        </div>

                                        {qrActivity &&
                                        <div className="mt-2">
                                            <BarcodeScannerComponent
                                                onUpdate={(err, result) => {
                                                if (result) {setCode(result.text);}
                                                }}
                                                delay={3000}
                                            />
                                            <p>{code}</p>
                                        </div>
                                        }

                                        {!qrActivity &&
                                        <div className="mt-2">
                                            <div className="input-group">
                                                <div className="input-group-text bg-dark border border-dark"><i className="fas fa-barcode fa-lg text-light"></i></div>
                                                <input type="text" className="form-control border border-dark" placeholder="QR Kod Okutunuz" ref={inputCode} />
                                                <button className="btn btn-outline-dark" type="button" onClick={preSellTicketByInput}>Bilet Sat</button>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                </div>
            </div>        
        </div>
    )
}

export default Sell
