import React, { useState, useEffect } from 'react'
import axios from 'axios'

import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

const CheckLicense = () => {
    const redux = useSelector((state) => state.redux);
    const [license, setLicense] = useState(true);

    let history = useHistory();

    useEffect(() => {
        const getLicenseDetailsFunc = async () => {
            if(redux.token) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/license`, {headers: {
                        Authorization: 'Bearer ' + redux.token
                    }});
                    if(response.data == false) {
                        setLicense(false);
                    }
                } catch (error) {
                    console.error(error);
                    setLicense(false);
                }
            }
        }

        if(license)
            getLicenseDetailsFunc();

        if(!license) {
            history.push({
                pathname: '/login',
                state: { message: {content: "Lisans doğrulanamadı, lütfen yöneticinizle görüşünüz", color: "alert-danger"} }
            });
        }
    }, [license])
}

export default CheckLicense
